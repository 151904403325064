import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PropertiesExpireDateService {
  constructor(private http: HttpClient) {}

  loadLookup(name: string) {
    const getValue = `name=${name}`;
    return this.http.get(`properties/lookup?${getValue}`);
  }

  loadUserProperties(userId: Number) {
    return this.http.get(`user/${userId}/properties`);
  }

  updatePropertiesExpireDate(data: {
    properties: number;
    expiration_date: string;
  }) {
    return this.http.put(`admin/update_properties_expirations`, data);
  }
}
