import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatterService } from '../../core/services/date-formatter.service';
import { PropertiesExpireDateService } from '../../services/properties-expire-date.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PropertiesExpireDateStoreEffects {
  constructor(
    private dataService: PropertiesExpireDateService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private dateformatter: DateFormatterService,
  ) {}

  loadLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLookupRequest),
      switchMap(({ value }) =>
        this.dataService.loadLookup(value).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadLookupSuccess({
              items: response.data.map(({ name, id, expire_date }) => ({
                title: `${name} - ${this.dateformatter.toUiFormat(
                  expire_date,
                )}`,
                key: id.toString(),
                description: name,
                direction: 'left',
              })),
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadLookupFailure(error));
          }),
        ),
      ),
    ),
  );

  loadUserProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUserPropertiesRequest),
      switchMap(({ userId }) =>
        this.dataService.loadUserProperties(userId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadUserPropertiesSuccess({
              items: response.data.map(({ name, id, expire_date }) => ({
                title: `${name} - ${this.dateformatter.toUiFormat(
                  expire_date,
                )}`,
                description: name,
                key: id.toString(),
                direction: 'left',
              })),
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadUserPropertiesFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.updatePropertiesExpireDate(payload).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.updateSuccess('properties');
            return fromActions.updateSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );
}
