import { createAction, props } from '@ngrx/store';

import { PropertiesExpireDate } from '../../models';

export const loadLookupRequest = createAction(
  '[Properties Expire Date] Load Lookup Request',
  props<{ value: string }>(),
);

export const loadLookupSuccess = createAction(
  '[Properties Expire Date] Load Lookup Success',
  props<{
    items: PropertiesExpireDate[];
  }>(),
);

export const loadLookupFailure = createAction(
  '[Properties Expire Date] Load Lookup Failure',
  props<{ error: any }>(),
);

export const loadUserPropertiesRequest = createAction(
  '[Properties Expire Date] Load User Properties Request',
  props<{ userId: number }>(),
);

export const loadUserPropertiesSuccess = createAction(
  '[Properties Expire Date] Load User Properties Success',
  props<{
    items: PropertiesExpireDate[];
  }>(),
);

export const loadUserPropertiesFailure = createAction(
  '[Properties Expire Date] Load User Properties Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Properties Expire Date] Update Request',
  props<{
    properties: number;
    expiration_date: string;
  }>(),
);

export const updateSuccess = createAction(
  '[Properties Expire Date] Update Success',
);

export const updateFailure = createAction(
  '[Properties Expire Date] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties Expire Date] Reset State');
